export const base64ToByteArray = (base64: string) => {
  base64 = base64.includes('base64') ? base64.split('base64,')[1] : base64;
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return byteArray;
};
