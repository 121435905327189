import { UploadSignedFileParams } from '../../../ts';
import { signRepositoryInstance } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/instances/signRepository';
import { signServices } from '../../../hexagonal-architecture-frontend-base/src/infrastructure/services/sign.service';

export const uploadSignedFileService = async ({
  docId,
  reqId,
  docName,
}: UploadSignedFileParams) => {
  const response = await signServices(signRepositoryInstance).uploadSignedFile(
    docId,
    reqId,
    docName,
  );

  if (typeof response === 'string') throw new Error(response);

  return response.data;
};
