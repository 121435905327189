import axios from 'axios';
import { ApiResponse, BstInfoJwt } from 'src/ts';

export const getBstInfoService = async (bst: string): Promise<BstInfoJwt | null> => {
  let bstInfo: ApiResponse<BstInfoJwt | null>;

  try {
    const response = await axios.get(`/blind-signup-tokens/${bst}`);

    bstInfo = response.data;
  } catch (error: any) {
    throw new Error(error.message);
  }

  if (bstInfo.isOk !== true) {
    throw new Error(bstInfo.message);
  }

  return bstInfo.data;
};
