import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import translationsES from './translations/es.json';
import translationsEN from './translations/en.json';
import { LOCAL_STORAGE_KEYS } from '../consts';

const localLanguage = localStorage.getItem(LOCAL_STORAGE_KEYS.LANGUAGE);
const setLanguage = (): string => {
  let language: string;
  if (localLanguage != null) {
    language = JSON.parse(localLanguage);
  } else if (document.cookie.includes('es') || navigator.language.includes('es')) {
    language = 'es';
  } else if (document.cookie.includes('en') || navigator.language.includes('en')) {
    language = 'en';
  } else {
    language = 'es';
  }
  document.cookie = navigator.language;
  localStorage.setItem(LOCAL_STORAGE_KEYS.LANGUAGE, JSON.stringify(language));
  return language;
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: setLanguage(),
    resources: {
      en: {
        translation: translationsEN,
      },
      es: {
        translation: translationsES,
      },
    },
    fallbackLng: 'es',
    //disabled in production
    debug: false,
    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
    },
    react: {
      useSuspense: false,
    },
  })
  .then((): null => null);

export { i18n };
