import { BlogRepository } from '../../domain/repositories/blog.repository';
import { http } from '../plugins/http/http';

export const blogRepository = (): BlogRepository => ({
  getBlogs: async () => {
    let returnValue;
    await http
      .cleanGet('/blog')
      .then(response => (returnValue = response))
      .catch(() => (returnValue = 'invalidFeed'));
    return returnValue;
  }
});
