import { atom } from 'jotai';
import { QUERY_KEYS } from '../../../../../consts';
import { getPatientsQuotesService } from '../../../../../services';
import { ExtraInfoMutationProps, IdToPatientExtraInfo, PatientQuote } from '../../../../../ts';
import { getAtomWithMutation, getExtraInfoArray } from '../../../../../utils';
import { selectAtom } from 'jotai/vanilla/utils';
import deepEquals from 'fast-deep-equal';

export const getPatientsQuotesAtom = getAtomWithMutation<
  IdToPatientExtraInfo<PatientQuote>,
  ExtraInfoMutationProps
>({
  mutationKey: QUERY_KEYS.PATIENTS.ALL_PATIENTS_QUOTES,
  mutationFn: getPatientsQuotesService,
});

const patientsQuotesDataAtom = selectAtom(
  getPatientsQuotesAtom,
  state => state,
  (a, b) => a.isPending || b.isPending || deepEquals(a.data, b.data),
);

export const allQuotesAtom = atom(get => {
  const getPatientsQuotes = get(getPatientsQuotesAtom);
  const patientsQuotesData = get(patientsQuotesDataAtom);

  const data = getPatientsQuotes.data ?? patientsQuotesData.data;

  if (getPatientsQuotes.isPending || getPatientsQuotes.isError || data == null) return null;

  return getExtraInfoArray(getPatientsQuotes.data) ?? null;
});
