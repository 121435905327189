import { useAtomValue } from 'jotai';
import { isLoadingAllPatientsAtom } from '../../../store/global/atoms/patients-atoms/getters';
import { isGettingAllPatientsAtom, isPatientsEmptyAtom } from '../../../store/global/atoms';

export const useIsLoadingAllPatients = () => {
  const isLoadingALlPatients = useAtomValue(isLoadingAllPatientsAtom);
  const isGettingAllPatients = useAtomValue(isGettingAllPatientsAtom);
  const isPatientsEmpty = useAtomValue(isPatientsEmptyAtom);

  const isLoading = isLoadingALlPatients || isGettingAllPatients;
  return {
    isLoading,
    isLoadingAndEmpty: isLoading && isPatientsEmpty,
  };
};
