import { useNavigate } from 'react-router-dom';

// Services
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';

// Images
import ayudaColor from '../../img/Menu/ayuda-color.png';
import ayudaGrey from '../../img/Menu/ayuda-grey.png';
import blogColor from '../../img/Menu/blog-color.png';
import blogGray from '../../img/Menu/blog-grey.png';
import pagosColor from '../../img/Menu/pagos-color.png';
import pagosGrey from '../../img/Menu/pagos-grey.png';
import personasColor from '../../img/Menu/personas-color.png';
import personasGrey from '../../img/Menu/personas-grey.png';
import tiendaGrey from '../../img/Menu/tienda-grey.png';
import tiendaColor from '../../img/Menu/tienda-color.png';
import { useSessionValue, useSettings } from '../../hooks';
import { AVAILABLE_SETTINGS } from '../../consts/settings/available-settings';

const MobileNavigationLogged = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { getSettingKey } = useSettings();
  const { userInfo } = useSessionValue();

  const activeStyle = {
    color: '#28337D',
  };

  const style = {
    color: 'rgba(39, 50, 124, 0.49)',
  };

  const patientsAccess = userInfo?.patientsAccess;

  /**************
   * JSX Values *
   **************/
  const linksDefinition = [
    {
      title: 'pages.personas.title',
      titleSub: 'pages.profile.title',
      paths: [
        '/allPatients',
        '/personas',
        'datosPersona',
        'datosDocumentos',
        'documentos',
        'facturas',
        'informeDetallado',
        'informes',
        'visitas',
      ],
      image: [personasColor, personasGrey],
      onClick: () => navigate('/personas'),
    },
    {
      title: 'pages.blog.title',
      titleSub: 'pages.blog.title',
      paths: ['/', '/blog'],
      image: [blogColor, blogGray],
      onClick: () => navigate('/blog'),
    },
    {
      title: 'pages.tienda.title',
      titleSub: 'pages.tienda.title',
      paths: ['/carrito', '/tienda', 'producto'],
      image: [tiendaColor, tiendaGrey],
      onClick: () => navigate('/tienda'),
    },
    {
      title: 'pedidos',
      titleSub: 'pedidos',
      paths: ['/pagos', 'servicios', 'subscripciones', 'pedidos'],
      image: [pagosColor, pagosGrey],
      onClick: () => navigate('/servicios'),
    },
    {
      title: 'help',
      titleSub: 'help',
      paths: ['/help'],
      image: [ayudaColor, ayudaGrey],
      customImageNSClass: 'pt-[2px]',
      customImageSClass: 'pt-[5px]',
      onClick: () => navigate('/help'),
    },
  ];

  const links = linksDefinition.map((item, index) => {
    if (
      getSettingKey(AVAILABLE_SETTINGS.HIDE_SHOP) !== 'false' &&
      item.title === 'pages.tienda.title'
    ) {
      return null;
    }

    return (
      <div
        onClick={item.onClick}
        key={index}
        className={`cursor-pointer`}
        style={
          (
            item.paths[0] === '/'
              ? item.paths.some(path => path === location.pathname)
              : item.paths.some(path => location.pathname.includes(path))
          )
            ? activeStyle
            : style
        }
      >
        <div className='m-auto w-[32px] h-[32px]'>
          <img
            src={
              (
                item.paths[0] === '/'
                  ? item.paths.some(path => path === location.pathname)
                  : item.paths.some(path => location.pathname.includes(path))
              )
                ? item.image[0]
                : item.image[1]
            }
            className={`m-auto ${
              item.paths.some(path => location.pathname.includes(path))
                ? item?.customImageSClass
                : item?.customImageNSClass
            }`}
            alt=''
          />
        </div>
        {patientsAccess ? (
          <a className='px-1 py-1'>{GlobalService.uppercaseFirstLetter(t(item.title))}</a>
        ) : (
          <a className='px-1 py-1'>{GlobalService.uppercaseFirstLetter(t(item.titleSub))}</a>
        )}
      </div>
    );
  });

  return (
    // To change the side of the bar switch to flex-col and change w-full to h-screen
    <footer
      className='fixed bottom-0 left-0 w-screen bg-white p-4 flex justify-between md:hidden z-50 drop-shadow-2xl overflow-y-scroll'
      style={
        location.pathname === '/login' || location.pathname === '/signup' ? { display: 'none' } : {}
      }
    >
      {links}
    </footer>
  );
};

export default MobileNavigationLogged;
