import { QUERY_KEYS } from '../../../../../consts';
import { getAtomWithMutation } from '../../../../../utils';
import { updateDealAfterPaymentService } from '../../../../../services';
import { getAllPatientsAtom } from '../../patients-atoms';

export const updateDealAfterPaymentAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.DEALS.UPDATE_AFTER_PAYMENT,
  mutationFn: () => updateDealAfterPaymentService,
  onSuccess: get => () => {
    const getAllPatients = get(getAllPatientsAtom);
    getAllPatients.mutate();
  },
});
