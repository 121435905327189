import styles from './consent-signature.module.css';
import classNames from 'classnames/bind';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Title from '../../components/Title';
import { TopMenu } from '../../components';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import '../../styles/Personas/Personas.css';
import LeftArrow from '../../img/PersonaImg/left-arrow.png';
import LeftArrowSmall from '../../img/PersonaImg/left-arrow-short.png';
import { useViewport, usePatientsValue, useSelectedPatient } from '../../hooks';
import { SignForm } from '../../components/sign/sign-form/index';

const cx = classNames.bind(styles);

export const ConsentSignature = () => {
  const { patientId, reportId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { patients, errorReason } = usePatientsValue();
  const { selectedPatient, setSelectedPatient } = useSelectedPatient();

  /**********
   * States *
   **********/
  const [errorText, setErrorText] = useState<string>('');

  const { viewportWidth } = useViewport();

  useEffect(() => {
    if (patients.length === 0) return;
    setSelectedPatient(patientId as string);
  }, [patients]);

  useEffect(() => {
    if (errorReason != null) {
      setErrorText(errorReason.message);
    }
  }, [selectedPatient]);

  const isScreenBig = viewportWidth > 768;
  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.mainTitle'))}:`;

  const desktopTitle = (
    <div className='flex text-left'>
      <img
        onClick={() => {
          navigate(`/informeDetallado/${patientId}/${reportId}/confirmation`);
        }}
        src={LeftArrow}
        alt='Back'
        className='Notificaciones-Arrow cursor-pointer'
      />
      <Title
        text={`${GlobalService.uppercaseFirstLetter(t('pages.pagos.signatureTitle'))}`}
        color='black'
        size={'24px'}
        margin={'0px 0px 0px 30px'}
      />
    </div>
  );

  const responsivePageTitle = GlobalService.uppercaseFirstLetter(t('pages.pagos.signatureTitle'));

  return (
    <div className={cx('main-container')}>
      <TopMenu
        title={isScreenBig ? pageTitle : responsivePageTitle}
        userName={isScreenBig ? selectedPatient.title : undefined}
        leftIcon={isScreenBig ? undefined : LeftArrowSmall}
        leftIconLink={`/informeDetallado/${patientId}/${reportId}/confirmation`}
      />
      <div className={cx('inner-container')}>
        {isScreenBig ? desktopTitle : null}
        <div className={cx('sign-form-container')}>
          <SignForm />
        </div>
      </div>
    </div>
  );
};
