import { useState } from 'react';
import { usePatientsValue } from '../use-patients/use-patients-value';
import { useIntersectionObserver } from '../../../hooks/use-intersection-observer';
// import { chunkArray } from '../../../utils';
import { useDebounce } from '../../../hooks';

export const usePatientsPagination = () => {
  const { patients } = usePatientsValue();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    value: searchString,
    debouncedValue: searchStringDebounced,
    setValue: setSearchString,
  } = useDebounce('');
  const { ref, isIntersecting } = useIntersectionObserver({
    onChange: () => setCurrentPage(curVal => curVal + 1),
    freezeOnceVisible: false,
  });
  // const [perPage, setPerPage] = useState(10);

  const searchedPatients = patients.filter(patient =>
    patient.title.toLocaleLowerCase().includes(searchStringDebounced.toLocaleLowerCase()),
  );

  // const chunkedPatients = chunkArray(patients, perPage);

  // const patientsToShow = chunkedPatients.slice(0, currentPage).flat();

  return {
    patientsToShow: searchedPatients,
    searchString,
    searchStringDebounced,
    setSearchString,
    ref,
    // setPerPage,
    currentPage,
    setCurrentPage,
  };
};
