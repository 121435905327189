import { selectAtom } from 'jotai/vanilla/utils';
import { QUERY_KEYS } from '../../../../../consts';
import { getPatientsService } from '../../../../../services';
import { PatientInfo } from '../../../../../ts';
import { getAtomWithMutation } from '../../../../../utils';

export const getAllPatientsAtom = getAtomWithMutation<PatientInfo[]>({
  mutationKey: QUERY_KEYS.PATIENTS.PATIENTS_LIST,
  mutationFn: getPatientsService,
});

export const isLoadingAllPatientsAtom = selectAtom(
  getAllPatientsAtom,
  state => state.isPending,
  (a, b) => a !== b,
);
