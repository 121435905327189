import { SignRepository } from '../../domain/repositories/sign.repository';

// Services
import { http } from '../plugins/http/http';

// Models
import { ISignTemplate } from '../../domain/models/IZohoSign';

export const signRepository = (): SignRepository => ({
  getDocInfo: async (docId: string) => {
    console.log('docId', docId);
    try {
      const response = await http.cleanGet('/signdoc/documentinfo', { docId });
      return response;
    } catch (error) {
      return 'invalidFile';
    }
  },
  getTemplateInfo: async (docId: string) => {
    console.log('docId', docId);
    try {
      const response = await http.cleanGet('/signdoc/templateinfo', { docId });
      return response;
    } catch (error) {
      console.log('Error EN CLEANET', error);

      return 'invalidFile';
    }
  },
  getSignUrl: async (params: { data: ISignTemplate; docId: string }) => {
    try {
      const paramsToSend = { data: params.data, docId: params.docId };
      const response = await http.cleanPost('/signdoc/requestsign', paramsToSend);
      return response;
    } catch (error) {
      console.log('error', error);
      return 'invalidFile';
    }
  },
  uploadSignedFile: async (docId: string, reqId: string, docName: string) => {
    try {
      const paramsToSend = { docId, reqId, docName };
      const response = await http.cleanGet('/signdoc/uploadsigned', paramsToSend);
      return response;
    } catch (error) {
      console.error('uploadsigned error: ', error);
      return 'invalidFile';
    }
  }
});
