import { useSetPopup } from '../use-popup';
import { PopupOptionsSetter } from '../../../ts';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const useNextStepBstPopup = () => {
  const { t } = useTranslation();
  const { openPopup, closePopup } = useSetPopup();
  const navigate = useNavigate();
  const modalText = "modal.nextStepBst"

  const popupOptions: PopupOptionsSetter = {
	title: t(`${modalText}.title`),
	children: t(`${modalText}.bodyText`),
    disableClickOutside: true,
	buttons: [
		{
			styleType: 'secondary',
			children: t(`${modalText}.logIn`),
			onClick: () => {
			navigate('/login')
			closePopup();
			},
		},
		{
			styleType: 'primary',
			children: t(`${modalText}.register`),
			onClick: () => {
				navigate('/signup')
				closePopup()
			},
		},
	]
  };

  const showNextStepBstPopup = () => {
    openPopup(popupOptions);
  };

  return { showNextStepBstPopup };
};
