import { NotificationsRepository } from '../../domain/repositories/notifications.repository ';

export const notificationsServices = (repository: NotificationsRepository): NotificationsRepository => ({
  getNotifications: async (numberItems?: number, currentPage?: number, seen?: string) => {
    return repository.getNotifications(numberItems, currentPage, seen);
  },
  getNotificationsCount: async () => {
    return repository.getNotificationsCount();
  },
  getNotificationsNonReadCount: async () => {
    return repository.getNotificationsNonReadCount();
  },
  markNotificationAsSeen: async (id: number) => {
    return repository.markNotificationAsSeen(id);
  }
});
