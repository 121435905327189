import { useEffect } from 'react';
import { useGetSinglePatientAttachedFiles } from '../use-attached-files';
import { useSelectedPatientValue } from './use-selected-patient-value';
import { useSetSelectedPatient } from './use-set-selected-patient';

export const useSelectedPatient = () => {
  const { selectedPatient, pendingVisits, nonPendingVisits, error } = useSelectedPatientValue();
  const { setSelectedPatient, removeSelectedPatient } = useSetSelectedPatient();
  const { getSinglePatientAttachedFiles, isLoading: isLoadingAttachedFiles } =
    useGetSinglePatientAttachedFiles();

  useEffect(() => {
    if (
      selectedPatient.attachments != null ||
      isLoadingAttachedFiles ||
      !selectedPatient.eternalReviewAuth
    ) {
      return;
    }

    getSinglePatientAttachedFiles({ patientId: selectedPatient.id });
  }, [selectedPatient]);

  return {
    selectedPatient,
    pendingVisits,
    nonPendingVisits,
    error,
    setSelectedPatient,
    removeSelectedPatient,
  };
};
