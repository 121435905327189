import { QUERY_KEYS } from '../../../../../consts';
import { deletePatomediService } from '../../../../../services';
import { DeletePatomediParams } from '../../../../../ts';
import { getAtomWithMutation } from '../../../../../utils';
import { getPatientsQuotesAtom } from '../getters';

export const deletePatomediAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.PATIENTS.DELETE_PATOMEDI,
  mutationFn: () => async (params: DeletePatomediParams) => {
    const response = await deletePatomediService(params);
    return response;
  },
  onSuccess: get => () => {
    get(getPatientsQuotesAtom).mutate({});
  },
});
