import { FC } from 'react';
import styles from './patient-button.module.css';
import classNames from 'classnames/bind';
import { BstPatientJwt } from 'src/ts';

const cx = classNames.bind(styles);

interface PatientButtonProps {
  patient: BstPatientJwt;
}

export const PatientButton: FC<PatientButtonProps> = ({ patient }) => {
  return <div className={cx('patient-btn')}>{patient.contactName}</div>;
};
