import styles from './edit-address.module.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAddressErrors } from '../../../hexagonal-architecture-frontend-base/src/domain/models/User';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import {
  useSetPopup,
  useShowAddressUpdateSuccessPopup,
  useShowErrorPopup,
  useUserAddress,
} from '../../../hooks';
import { AddressData } from '../../../ts';
import { validate } from '../../../utils';
import { AddressInput } from './address-input';
import { Button } from '../../Buttons/button/button';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const EditAddress = () => {
  const { t } = useTranslation();
  const { closePopup, setForceKeepOpen } = useSetPopup();
  const { userAddress, updateUserAddress, isUpdating: isUpdatingUserAddress } = useUserAddress();
  const { showAddressUpdateSuccessPopup } = useShowAddressUpdateSuccessPopup();
  const { showErrorPopup } = useShowErrorPopup();
  const [newAddress, setNewAddress] = useState<AddressData>({ ...userAddress });
  const [errorMessage, setErrorMessage] = useState<UserAddressErrors>({
    Direcci_n: '',
    Codigo_postal: '',
    Ciudad: '',
    Pa_s: '',
  });

  const inputsDefinition = [
    {
      customClass: 'Address-Input',
      id: 'Direcci_n',
      name: 'pages.tienda.addressToSendDirection',
      placeholder: GlobalService.uppercaseFirstLetter(t('pages.tienda.addressToSendDirection')),
      value: newAddress.Direcci_n,
      error: 'errors.directionIsInvalid',
      onChange: (e: string) => handleValidation('Direcci_n', e),
    },
    {
      customClass: 'Address-Input-Small',
      id: 'Codigo_postal',
      name: 'pages.tienda.addressToSendCP',
      placeholder: GlobalService.uppercaseFirstLetter(t('pages.tienda.addressToSendCP')),
      value: newAddress.Codigo_postal,
      error: 'errors.postalCodeIsInvalid',
      onChange: (e: string) => handleValidation('Codigo_postal', e),
    },
    {
      customClass: 'Address-Input-Small',
      id: 'Ciudad',
      name: 'pages.tienda.addressToSendCity',
      placeholder: GlobalService.uppercaseFirstLetter(t('pages.tienda.addressToSendCity')),
      value: newAddress.Ciudad,
      error: 'errors.cityIsInvalid',
      onChange: (e: string) => handleValidation('Ciudad', e),
    },
    {
      customClass: 'Address-Input-Small',
      id: 'Pa_s',
      name: 'pages.tienda.addressToSendCountry',
      placeholder: GlobalService.uppercaseFirstLetter(t('pages.tienda.addressToSendCountry')),
      value: newAddress.Pa_s,
      error: 'errors.countryIsInvalid',
      onChange: (e: string) => handleValidation('Pa_s', e),
    },
  ];

  const handleValidation = (field: string, e: string) => {
    const errorToShow: any = inputsDefinition.filter(def => def.id === field)[0]?.error;
    const errorToSet = GlobalService.uppercaseFirstLetter(t(errorToShow));
    if (e && !validate(e, (field as 'Direcci_n') || 'Codigo_postal' || 'Ciudad' || 'Pa_s')) {
      setErrorMessage(error => ({ ...error, [field]: errorToSet }));
    } else {
      setErrorMessage(error => ({ ...error, [field]: '' }));
    }
    setNewAddress(address => ({ ...address, [field]: e }));
  };

  // TODO: Fix Record_Image properly
  const isSaveDisabled =
    Object.entries(newAddress).some(
      ([key, value]) => key !== 'id' && key !== 'Name' && key !== 'Record_Image' && !value,
    ) || Object.values(errorMessage).some(value => value);

  const handleUpdateUserAddress = (addressData: AddressData) => {
    setForceKeepOpen.enable();
    updateUserAddress(addressData, {
      onSuccess: () => {
        showAddressUpdateSuccessPopup();
      },
      onError: error => {
        showErrorPopup(error.message);
      },
    });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('inputs-container')}>
        {inputsDefinition.map((item, index) => (
          <AddressInput
            key={index}
            id={item.id}
            name={item.name}
            value={item.value}
            placeholder={item.placeholder}
            customClass={item.customClass}
            onChange={isUpdatingUserAddress ? () => {} : item.onChange}
            errorMessage={errorMessage[item.id as keyof UserAddressErrors] ?? ''}
          />
        ))}
      </div>

      <div className={cx('button-container')}>
        <Button styleType='secondary' onClick={() => closePopup()}>
          {GlobalService.uppercaseFirstLetter(t('pages.tienda.cancel'))}
        </Button>
        <Button
          styleType='primary'
          onClick={() => handleUpdateUserAddress(newAddress)}
          disabled={isSaveDisabled}
          isLoading={isUpdatingUserAddress}
        >
          {GlobalService.uppercaseFirstLetter(t('pages.tienda.save'))}
        </Button>
      </div>
    </div>
  );
};
