import React from 'react';

interface Props {
  type?: string;
  size?: string;
  border?: string;
  color?: string;
  spinnerColor?: string;
}

const animationTime = '0.7s';

export const Loader: React.FC<Props> = ({
  type,
  size = '50px',
  border = '8px',
  color = '#DADADA',
  spinnerColor = 'var(--color-purple)',
}) => {
  const REGULAR_SPINNER_STYLES = {
    width: size,
    height: size,
    borderRadius: '50%',
    margin: 'auto',
    border: `${border} solid ${color}`,
    borderTopColor: spinnerColor,
    animation: `loader ${animationTime} infinite linear`,
  };

  switch (type) {
    case 'regular':
      return <div style={REGULAR_SPINNER_STYLES}></div>;
    case 'gradient':
      return (
        <div
          style={{
            width: size,
            height: size,
            borderRadius: '50%',
            margin: 'auto',
            background: `conic-gradient(#0000 10%,${spinnerColor})`,
            WebkitMask: `radial-gradient(farthest-side,#0000 calc(100% - ${border}),#000 0)`,
            animation: `loader ${animationTime} infinite linear`,
          }}
        ></div>
      );
    case 'dot':
      return (
        <div
          style={{
            width: size,
            height: size,
            borderRadius: '50%',
            margin: 'auto',
            background: `radial-gradient(farthest-side,${spinnerColor} 95%,#0000) 50% 1px/12px 12px no-repeat, radial-gradient(farthest-side,#0000 calc(100% - 14px),${color} 0)`,
            animation: `loader ${animationTime} infinite linear`,
          }}
        ></div>
      );
    case 'roundGradient':
      return (
        <div
          style={{
            width: size,
            height: size,
            borderRadius: '50%',
            margin: 'auto',
            background: `radial-gradient(farthest-side,${spinnerColor} 94%,#0000) top/8px 8px no-repeat, conic-gradient(#0000 30%,${spinnerColor})`,
            WebkitMask: `radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0)`,
            animation: `loader ${animationTime} infinite linear`,
          }}
        ></div>
      );
    default:
      return <div style={REGULAR_SPINNER_STYLES}></div>;
  }
};

export default Loader;
