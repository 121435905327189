import React from 'react';

//Services
import { GlobalService } from '../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { useViewport } from '../hooks/use-viewport';

interface Props {
  currentPage: number; // Current page
  pageNeighbours?: 0 | 1 | 2; // Additional pages to show besides the current page
  totalPages?: number; // Number of pages
  buttonBG?: string; // Background for buttons
  currentPageBG?: string; // Background for current page button
  onChange: (e: number) => void; // Callback function
}

export const Pagination: React.FC<Props> = ({
  currentPage,
  pageNeighbours = 1,
  totalPages = 10,
  buttonBG,
  currentPageBG = '#E96E81',
  onChange,
}) => {
  const { t } = useTranslation();

  const { viewportWidth } = useViewport();

  /************
   * Handlers *
   ************/
  const handleBackwards = () => {
    if (currentPage === 1) return;
    else onChange(currentPage - 1);
  };

  const handleForward = () => {
    if (currentPage === totalPages) return;
    else onChange(currentPage + 1);
  };

  const handleGoToPage = (page: number) => {
    onChange(page);
  };

  /**************
   * JSX Values *
   **************/
  const startPage: number =
    currentPage === 1 || currentPage - pageNeighbours < 1 ? 1 : currentPage - pageNeighbours;
  const endPage: number =
    currentPage + pageNeighbours > totalPages ? totalPages : currentPage + pageNeighbours;
  const pagesToShow = [];

  for (let page = startPage; page <= endPage; page++) {
    if (page === startPage && startPage > 1) {
      pagesToShow.push(
        <div key='0A' className='flex'>
          <div
            className={'flex items-center border rounded-full cursor-pointer w-8 h-8'}
            onClick={() => {
              handleGoToPage(1);
            }}
          >
            <p className='w-min mx-auto mt-1'>{1}</p>
          </div>
          {page === startPage && startPage > 2 ? <div className='flex items-end'>...</div> : null}
        </div>,
      );
    }
    if (page === currentPage) {
      pagesToShow.push(
        <div
          key={page}
          className={`flex items-center rounded-full text-white bg-slate-400 ${
            currentPage !== totalPages ? 'cursor-pointer' : ''
          } w-8 h-8`}
          style={{ background: currentPageBG }}
        >
          <p className='w-min mx-auto mt-1'>{page}</p>
        </div>,
      );
    } else {
      pagesToShow.push(
        <div
          key={page}
          className='flex items-center border rounded-full cursor-pointer w-8 h-8'
          onClick={() => {
            handleGoToPage(page);
          }}
        >
          <p className='w-min mx-auto mt-1'>{page}</p>
        </div>,
      );
    }
  }
  if (endPage < totalPages) {
    pagesToShow.push(
      <div key='1A' className='flex'>
        {endPage < totalPages - 1 ? <div className='flex items-end'>...</div> : null}
        <div
          className={'flex items-center border rounded-full cursor-pointer w-8 h-8'}
          onClick={() => {
            handleGoToPage(totalPages);
          }}
        >
          <p className='w-min mx-auto mt-1'>{totalPages}</p>
        </div>
      </div>,
    );
  }

  /*******
   * JSX *
   *******/
  return (
    <div className='flex pt-4 justify-center'>
      <div className='flex'>
        <button
          onClick={handleBackwards}
          className={`rounded-full p-1 ${currentPage !== totalPages ? 'cursor-pointer' : ''}`}
          disabled={currentPage === 1}
          style={{ background: buttonBG, color: currentPage === 1 ? '#CCCCCC' : '#333333' }}
        >
          {viewportWidth < 500 ? '<<' : GlobalService.uppercaseJustFirstLetter(t('previous'))}
        </button>
      </div>
      <div className='flex gap-1 ml-2 mr-2'>{pagesToShow}</div>
      <div className='flex'>
        <button
          onClick={handleForward}
          className={`rounded-full p-1 ${currentPage === totalPages ? '' : 'cursor-pointer'}`}
          disabled={currentPage === totalPages}
          style={{
            background: buttonBG,
            color: currentPage === totalPages ? '#CCCCCC' : '#333333',
          }}
        >
          {viewportWidth < 500 ? '>>' : GlobalService.uppercaseJustFirstLetter(t('next'))}
        </button>
      </div>
    </div>
  );
};
