import { useAtomValue } from 'jotai';
import { getQuoteProductsAtom } from '../../../store/global/atoms';

export const useGetQuoteProducts = () => {
  const getQuoteProducts = useAtomValue(getQuoteProductsAtom);

  return {
    quoteProducts: getQuoteProducts.data,
    getQuoteProducts: getQuoteProducts.mutate,
    getQuoteProductsAsync: getQuoteProducts.mutateAsync,
    isLoading: getQuoteProducts.isPending,
    error: getQuoteProducts.error,
  };
};
