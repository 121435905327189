import React from 'react';
import styles from './data-protection.module.css';
import { TopMenu } from '../../components';
import { useTranslation } from 'react-i18next';

export const DataProtection = () => {
  const { t } = useTranslation();
  const textBase = 'pages.dataProtection'
  return (
    <div className={styles.container}>
      <TopMenu title={t(`${textBase}.dataProtectionPolicy`)} />
      <h1 className={styles.title}>{t(`${textBase}.dataProtectionPolicy`)}</h1>
      <p className={styles.listItem}>{t(`${textBase}.responsibleForTreatment`)}
      </p>
      <ul>
        <li className={styles.listItem}>
        {t(`${textBase}.legalBasis`)}
        </li>
        <li className={styles.listItem}>
        {t(`${textBase}.dataWillBeProcessed`)}
        </li>
        <li className={styles.listItem}>
        {t(`${textBase}.dentalResidencyMayCommunicate`)}
          <ul>
            <li className={styles.listItem}>
            {t(`${textBase}.dentistsInCharge`)}
            </li>
            <li className={styles.listItem}>{t(`${textBase}.patientRelatives`)}</li>
            <li className={styles.listItem}>
            {t(`${textBase}.hasCollaborationAgreement`)}
            </li>
            <li className={styles.listItem}>
            {t(`${textBase}.publicAdministrations`)}
            </li>
            <li className={styles.listItem}>
            {t(`${textBase}.stateSecurityForces`)}
            </li>
            <li className={styles.listItem}>
            {t(`${textBase}.bankingEntities`)}
            </li>
          </ul>
        </li>
        <li className={styles.listItem}>
        {t(`${textBase}.dataMayBeCommunicated`)}
        </li>
        <li className={styles.listItem}>
        {t(`${textBase}.communicationWithFamily`)}
        </li>
        <li className={styles.listItem}>
        {t(`${textBase}.interestedParties`)}
        </li>
        <li className={styles.listItem}>
        {t(`${textBase}.sendYourRequest`)} <span className={styles.link}>dpd@luracare.es</span>.
        </li>
        <li className={styles.listItem}>
        {t(`${textBase}.interestedPartiesHaveRight`)}
        </li>
        <li className={styles.listItem}>
        {t(`${textBase}.dataProtectionOfficer`)}
        </li>
      </ul>
    </div>
  );
};
