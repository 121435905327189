import axios from 'axios';
import { ApiResponse, VerifyPatientBirthDate } from 'src/ts';

export const verifyPatientBirthDateService = async (data: VerifyPatientBirthDate) => {
  let responseData: ApiResponse<boolean>;

  try {
    const response = await axios.post<ApiResponse<boolean>>('/patients/verify-patient-birth-date', {
      ...data,
    });

    responseData = response.data;
  } catch (error: any) {
    throw new Error(error.response.data.message);
  }

  if (!responseData.isOk) {
    if (responseData.message === 'Max tries reached') {
      throw new Error('modal.addPatientFromBst.errors.maxTriesReached');
    }

    throw new Error('modal.addPatientFromBst.errors.birthDateIncorrect');
  }

  return responseData.data;
};
