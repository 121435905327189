import styles from './add-patients-from-bst.module.css';
import classNames from 'classnames/bind';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Buttons';
import {
  useBlindSignupToken,
  usePatients,
  useSession,
  useSetPopup,
  useShowErrorPopup,
} from '../../../hooks';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

export const AddPatientFromBst = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedBstPatient, setTutorToPatient, isSetTutorToPatientLoading, cleanBstInfo } =
    useBlindSignupToken();
  const { userInfo } = useSession();
  const { closePopup, setForceKeepOpen } = useSetPopup();
  const { showErrorPopup } = useShowErrorPopup();
  const { getAllPatients } = usePatients();

  const handleAddPatient = async () => {
    console.log('clicked');
    if (userInfo == null) return;

    setForceKeepOpen.enable();

    console.log('loading');

    setTutorToPatient(userInfo.id, {
      onSuccess: () => {
        console.log('success');
        getAllPatients();
        setForceKeepOpen.disable();
        cleanBstInfo();
        navigate('/personas');
        closePopup();
      },
      onError: () => {
        setForceKeepOpen.disable();
        showErrorPopup(t('modal.addPatientFromBst.unexpectedError'));
      },
    });
  };

  return (
    <div className={cx('container')}>
      {t('modal.addPatientFromBst.bodyText', {
        tutorName: userInfo?.name,
        contactName: selectedBstPatient?.contactName,
      })}

      <div className={cx('btns-container')}>
        <Button
          styleType='secondary'
          onClick={() => closePopup()}
          fullWidth={true}
          disabled={isSetTutorToPatientLoading}
        >
          {t('modal.addPatientFromBst.cancel')}
        </Button>
        <Button onClick={handleAddPatient} fullWidth={true} isLoading={isSetTutorToPatientLoading}>
          {t('modal.addPatientFromBst.add')}
        </Button>
      </div>
    </div>
  );
};
