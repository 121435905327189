import styles from './select.module.css';
import { MouseEvent, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import type { SelectProps } from 'src/ts/interfaces/components';
import { useClickOutside } from '../../hooks';
import { CaretDown } from '../icons';
import { Input } from '../Inputs';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import deepEquals from 'fast-deep-equal';

const cx = classNames.bind(styles);

export const Select = <T extends object | string>({
  label,
  defaultValue,
  setValue,
  options,
  search,
  size = 'medium'
}: SelectProps<T>) => {
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState(() => {
    if (defaultValue == null) return null;
    if (typeof defaultValue === 'string') return defaultValue as T;
    const val = options.find(opt => deepEquals(opt.value, defaultValue))?.value ?? null;
    return val;
  });

  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);
  const [searchValue, setSearchValue] = useState('');

  const toggleIsOpen = (forceState?: boolean) => {
    setIsOpen(curVal => (forceState != null ? forceState : !curVal));
  };

  useClickOutside(selectRef, () => toggleIsOpen(false));

  const getOptionContent = (value: T | null) =>
    value == null ? null : options.find(opt => opt.value === value)?.content ?? null;

  const handleClickOption = (newVal: T, e: MouseEvent<HTMLDivElement>) => {
    setValue(newVal, e);

    setSelectedValue(newVal);
    toggleIsOpen(false);
  };
  console.log('SIZE', size)
  const searchedOptions =
    searchValue === ''
      ? options
      : options.filter(opt =>
          opt.searchValue?.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase()),
        );

  return (
    <div
      className={cx( 'dropdown', {dropdown__large: size === 'large'} )}
      role='combox'
      aria-expanded='false'
      aria-haspopup='listbox'
      aria-label={label}
      ref={selectRef}
    >
      <div
        className={cx('dropdown_header', 'content_container', { 'dropdown_header-open': isOpen })}
        tabIndex={0}
        aria-controls='dropdownList'
        onClick={() => toggleIsOpen()}
      >
        {getOptionContent(selectedValue) ?? label}
        <CaretDown width='20' />
      </div>

      {isOpen && (
        <div className={cx('dropdown_list', { 'dropdown_list-hidden': !isOpen })} role='listbox'>
          {search?.visible && (
            <>
              <label className={cx('search-container')}>
                <FontAwesomeIcon icon={faMagnifyingGlass} className={cx('magnifying-glass')} />

                <Input
                  id='searchInput'
                  type='text'
                  placeholder={t('search') as string}
                  value={searchValue}
                  className={cx('search-input')}
                  name='searchInput'
                  onChange={val => setSearchValue(val)}
                  label=''
                />
              </label>
            </>
          )}

          <div>
            {searchedOptions.map(opt => (
              <div
                key={JSON.stringify(opt.value)}
                className={cx('dropdown_item', 'content_container', {
                  'dropdown_item-selected': opt.value === selectedValue,
                })}
                role='option'
                data-value={opt.value}
                tabIndex={-1}
                onClick={e => handleClickOption(opt.value, e)}
              >
                {opt.content}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
