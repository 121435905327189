export const QUERY_KEYS = {
  USER_SESSION: {
    LOGIN: 'userLoginQuery',
    REGISTER: 'userRegisterQuery',
    SEND_VERIFY_MOBILE_CODE: 'verifyUserMobileQuery',
    VERIFY_MOBILE_CODE: 'verifyMobileCodeQuery',
    GET_MOBILE_IS_REGISTERED: 'getMobileIsRegisteredQuery',
  },
  USER: {
    USER_INFO: 'userInfoQuery',
    UPDATE_USER: 'updateUserQuery',
  },
  LOGIN_PAGE: {
    LOGIN: 'loginQuery',
  },
  PATIENTS: {
    PATIENTS_LIST: 'patientsListQuery',
    ALL_PATIENTS_DEALS: 'allPatientsDealsQuery',
    ALL_PATIENTS_QUOTES: 'allPatientsReportsQuery',
    ALL_PATIENTS_VISITS: 'allPatientsVisitsQuery',
    ALL_PATIENTS_ATTACHED_FILES: 'allPatientsAttachedFilesQuery',
    PATIENT_DEALS: 'patientDealsQuery',
    DELETE_PATOMEDI: 'deletePatomediQuery',
    DOWNLOAD_DEAL: 'downloadDealQuery',
    DOWNLOAD_ATTACHED_FILE: 'downloadAttachedFileQuery',
    MARK_SERVICE_AS_PAID: 'markServiceAsPaidQuery',
    UPLOAD_SIGNED_FILE: 'uploadSignedFileQuery',
    INVOICES: 'patientInvoicesQuery',
    UPDATE: 'updatePatientQuery',
    SET_QUOTE_AUDIO_AS_LISTENED: 'setQuoteAudioAsListenedQuery',
    GET_IS_QUOTE_AUDIO_LISTENED: 'getIsQuoteAudioListenedQuery',
  },
  SETTINGS: 'settingsQuery',
  DOCUMENTS: {
    TEMPLATE_INFO: 'templateInfoQuery',
    DOCUMENT_INFO: 'documentInfoQuery',
    SIGN_URL: 'signUrlQuery',
    UPLOAD_PATOMEDI: 'uploadPatomediQuery',
  },
  QUOTES: {
    QUOTE_PRODUCTS: 'quoteProductsQuery',
    DOWNLOAD_PDF: 'downloadQuotePdfQuery',
    UPDATE_QUOTE_PAYMENT_TYPE: 'updateQuotePaymentTypeQuery',
    DOWNLOAD_QUOTE_MEDIA: 'downloadQuoteMediaQuery',
  },
  DEALS: {
    UPDATE_AFTER_PAYMENT: 'updateDealAfterPaymentQuery',
  },
  PAYMENT: {
    QUOTE_PAYMENT_CHECKOUT: 'quotePaymentCheckoutQuery',
    USER: {
      ADDRESS: 'paymentUserAddressQuery',
      UPDATE_ADDRESS: 'paymentUpdateUserAddressQuery',
    },
  },
  BST: {
    GET_INFO: 'getBstInfoQuery',
    UPDATE_PATIENT_TUTOR: 'updatePatientTutorQuery',
    VERIFY_PATIENT_BIRTH_DATE: 'verifyPatientBirthDateQuery',
  },
} as const;
