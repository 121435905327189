import { useTranslation } from 'react-i18next';
import { useSetPopup } from '../use-popup';
import { PopupOptionsSetter } from '../../../ts';
import { useSettings } from '../../../hooks/use-settings';
import { AVAILABLE_SETTINGS, PHONE_PREFIX } from '../../../consts';

export const useMaxBstTriesReached = () => {
  const { t } = useTranslation();
  const { openPopup } = useSetPopup();
  const { getSettingKey } = useSettings();

  const link = `tel:${PHONE_PREFIX}${getSettingKey(AVAILABLE_SETTINGS.CALL).replaceAll(' ', '')}`;

  const popupOptions: PopupOptionsSetter = {
    title: t('modal.addPatientFromBst.errors.maxTriesReachedTitle'),
    children: <p>{t('modal.addPatientFromBst.errors.maxTriesReached')}</p>,
    buttons: [
      {
        children: t('callUs'),
        link,
        fullWidth: true,
      },
    ],
  };

  const showMaxBstTriesReachedPopup = () => openPopup(popupOptions);

  return { showMaxBstTriesReachedPopup };
};
