import { useSetAtom } from 'jotai';
import { selectedPatientIdAtom } from '../../../store/global/atoms';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

export const useSetSelectedPatient = () => {
  const setSelectedPatientId = useSetAtom(selectedPatientIdAtom);
  const { patientId } = useParams();

  const setSelectedPatient = (patientId: string) => {
    setSelectedPatientId(patientId);
  };

  const removeSelectedPatient = () => {
    setSelectedPatientId(null);
  };

  useEffect(() => {
    if (patientId == null) return;
    setSelectedPatient(patientId);
  }, [patientId]);

  return { setSelectedPatient, removeSelectedPatient };
};
