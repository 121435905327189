import PrivateRoutes from './routes/PrivateRoutes';
import NotLoggedRoutes from './routes/not-logged-routes';
import { Navigate, Route, Routes } from 'react-router-dom';

// Components
import {
  AllPatients,
  ConfirmQuote,
  Help,
  Login,
  Signup,
  DatosPersonaYServicio,
  // Pagos,
  Services,
  Visits,
  QuoteDetails,
  CareHomesBst,
} from './views';
import Blog from './views/Blog';
// import Carrito from './views/Carrito';
// import ChangePassword from './views/ChangePassword';
import DatosPersonaDocumentos from './views/DatosPersonaDocumentos';
import Documentos from './views/Documentos';
import Facturas from './views/Facturas';
// import ForgotPassword from './views/ForgotPassword';
import Informes from './views/informes/Informes';
import Notificaciones from './views/Notificaciones';
// import Pedidos from './views/Pedidos';
// import Personas from './views/Personas';
// import Producto from './views/Producto';
// import ResetPassword from './views/ResetPassword';
// import SolicitudVisitaServicio from './views/SolicitudVisitaServicio';
// import Subscripciones from './views/Subscripciones';
// import Tienda from './views/Tienda';
import { ConsentSignature } from './views/consent-signature/consent-signature';
// import Signature from './views/Signature';
import PaymentMethod from './views/payment-method/payment-method';
import { Profile } from './views/profile/profile';
import { useSession } from './hooks';
import { MainLayout, PagesLayout } from './components/layouts';
import SplitPayment from './views/split-payment/split-payment';
import TransferPayment from './views/transfer-payment/transfer-payment';
import VisaPayment from './views/VisaPayment/visa-payment';
import { DataProtection } from './views/data-protection';

function App() {
  const { isUserLogged } = useSession();

  return (
    <MainLayout>
      <PagesLayout>
        <Routes>
          <Route path='/blog' element={<Blog />} />
          <Route path='/help' element={<Help />} />
          <Route path='/residencias/:careHomeBst' element={<CareHomesBst />} />
          <Route path='/data-protection' element={<DataProtection />} />
        </Routes>

        <Routes>
          <Route element={<NotLoggedRoutes />}>
            <Route
              path='/'
              element={isUserLogged ? <Navigate to='/personas' replace /> : <Blog />}
            />
            {/* <Route path='/changePassword' element={<ChangePassword />} /> */}
            <Route path='/login' element={<Login />} />
            {/* <Route path='/resetPassword/:token' element={<ResetPassword />} /> */}
            <Route path='/signup' element={<Signup />} />

            {/* <Route path='/forgotPassword' element={<ForgotPassword />} /> */}
            {/* <Route path='/users/:userId/:verify' element={<VerifyEmail />} /> */}
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path='/' element={!isUserLogged ? <Navigate to='/login' replace /> : <Blog />} />
            {/* <Route path='/blog' element={<Blog />} /> */}
            {/* <Route path='/allPatients' element={<AllPatients />} /> */}
            {/* <Route path='/carrito' element={<Carrito />} /> */}
            <Route path='/personas' element={<AllPatients />} />
            <Route path='/datosPersona/:patientId' element={<DatosPersonaYServicio />} />
            <Route path='/datosDocumentos/:patientId' element={<DatosPersonaDocumentos />} />
            <Route path='/documentos/:patientId' element={<Documentos />} />
            <Route path='/visitas/:patientId' element={<Visits />} />
            <Route path='/facturas/:patientId' element={<Facturas />} />
            <Route path='/informes/:patientId' element={<Informes />} />
            <Route path='/informeDetallado/:patientId/:reportId' element={<QuoteDetails />} />
            <Route
              path='/informeDetallado/:patientId/:reportId/confirmation'
              element={<ConfirmQuote />}
            />
            <Route
              path='/informeDetallado/:patientId/:reportId/firmaConsentimiento'
              element={<ConsentSignature />}
            />
            <Route
              path='/informeDetallado/:patientId/:reportId/paymentMethod'
              element={<PaymentMethod />}
            />
            <Route
              path='/informeDetallado/:patientId/:reportId/paymentMethod/visapayment'
              element={<VisaPayment />}
            />
            <Route
              path='/informeDetallado/:patientId/:reportId/paymentMethod/transferpayment'
              element={<TransferPayment />}
            />
            <Route
              path='/informeDetallado/:patientId/:reportId/paymentMethod/splitpayment'
              element={<SplitPayment />}
            />
            {/* <Route
              path='/informeDetallado/:patientId/:reportId/signature'
              element={<Signature />}
            /> */}
            <Route path='/notificaciones' element={<Notificaciones />} />
            {/* <Route path='/pagos' element={<Pagos />} /> */}
            {/* <Route path='/pedidos' element={<Pedidos />} /> */}
            {/* <Route path='/personas' element={<Personas />} /> */}
            {/* <Route path='/producto/:itemId' element={<Producto />} /> */}
            <Route path='/servicios' element={<Services />} />
            {/* <Route path='/solicitudVisitaServicio' element={<SolicitudVisitaServicio />} /> */}
            {/* <Route path='/subscripciones' element={<Subscripciones />} /> */}
            {/* <Route path='/tienda' element={<Tienda />} /> */}
            <Route path='/profile' element={<Profile />} />
          </Route>
        </Routes>
      </PagesLayout>
    </MainLayout>
  );
}

export default App;
