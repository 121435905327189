import { useAtomValue } from 'jotai';
import { getSignUrlAtom } from '../../../store/global/atoms/documents-atoms/get-sign-url-atom';

export const useSignUrl = () => {
  const getSignUrl = useAtomValue(getSignUrlAtom);

  return {
    signUrl: getSignUrl.data,
    getSignUrl: getSignUrl.mutate,
    getSignUrlAsync: getSignUrl.mutateAsync,
    isLoading: getSignUrl.isPending,
    error: getSignUrl.error,
  };
};
