import classNames from 'classnames/bind';
import styles from './lura-care-logo-white.module.css';

const cx = classNames.bind(styles);

interface LuraCareLogoProps {
  width?: string;
  height?: string;
  className?: string;
  styles?: React.CSSProperties;
}

export const LuraCareLogoWhite = ({
  width = '3rem',
  height = '100%',
  className,
  styles,
  ...rest
}: LuraCareLogoProps) => {
  return (
    <svg
      id='Capa_1'
      data-name='Capa 1'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1211.95 2125'
      width={width}
      height={height}
      className={className}
      style={styles}
      {...rest}
    >
      <defs></defs>
      <path
        className={cx('cls-1')}
        style={{ fill: '#fff', strokeWidth: '0px' }}
        d='m1173.72,392.01c-77.61-201.04-232.67-319.16-400.91-369.06-60.49-17.94-146.33-28.62-231.63-19.8-110.57,11.43-214.3,51.81-302.38,119.81C85.2,241.54-.63,410.24,0,604.44c.37,113.48,28.82,213.08,93.28,323.36,128.69,220.18,256.24,441.03,384.53,661.45,5.97,10.26,12.36,20.56,20.2,29.41,63.34,71.49,181.66,58.7,231.38-25.23,39.18-66.16,77.52-132.81,116.11-199.32,96.45-166.26,195.22-331.19,289.52-498.61,90.72-161.07,98-349.92,38.72-503.49Zm-415.55,529.67c-38.73,34.81-78.76,70.8-120.19,110.2-8.9,8.46-20.38,12.7-31.85,12.7s-22.35-4-31.16-12.07c-35.56-32.46-69.63-62.62-101.68-91.01-164.6-145.82-283.54-251.17-283.54-386.57,0-150.12,101.57-267.71,231.23-267.71,87.19,0,148.82,41.06,185.16,75.48,36.34-34.42,97.95-75.49,185.16-75.49,129.66,0,231.23,117.6,231.23,267.72,0,129.05-116.65,233.94-264.34,366.75Z'
      ></path>
      <path
        className={cx('cls-1')}
        style={{ fill: '#fff', strokeWidth: '0px' }}
        d='m96.68,1883.7c12.09-28.07,33.39-48.44,58.31-64.99,35.89-23.83,65.62-35.29,99.19-46.71,20.73-7.05,53-15.88,63.38-18.58,34.32-8.92,53.57-11.79,67.1,10.17,4.79,7.77,7.19,12.14,12.51,23.18,9.89,20.54.9,35.66-42.39,45.73-18.49,4.3-86.73,23.34-136.67,50.81-11.78,6.48-23.05,13.88-32.15,23.93-10.34,11.42-10.26,20.33.37,31.6,15.73,16.67,35.76,26.94,56.33,36.2,52.12,23.44,107.23,36.54,163.36,45.75,99.14,16.28,198.98,18.49,298.96,11.66,82.04-5.6,162.92-18.49,240.37-47.59,24.55-9.23,48.36-20.03,69.28-36.19,24.63-19.03,24.39-33.84-.7-51.89-40.79-29.35-87.83-43.82-135.65-56.57-25.2-6.72-6.42-1.35-32.21-8.75-43.3-12.43-38.48-27.54-30.61-46.37,3.62-8.67,5.07-10.49,10.55-18.79,15.53-23.51,40.25-19.14,69.76-11.49,57.96,15.02,66.71,17.53,123.22,41.8,34.22,14.7,64.5,36.61,85.37,68.46,28.28,43.17,24.27,90.69-10.13,129.2-28.85,32.28-65.97,52.4-105.38,68.47-71.42,29.13-146.44,43.76-222.76,52.8-86.86,10.29-173.92,12.19-261.09,5.7-96.54-7.19-191.63-22.03-282.06-58.39-35.71-14.36-69.17-32.69-97.15-59.62-16.76-16.12-28.89-35.1-34.73-57.73-6.41-24.83-2.26-43.48,5.63-61.81Z'
      ></path>
    </svg>
  );
};
