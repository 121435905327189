import { atom } from 'jotai';
import { QUERY_KEYS } from '../../../../consts';
import { getBstInfoService, setTutorToPatientInBstService } from '../../../../services';
import { getAtomWithMutation } from '../../../../utils';
import { BstPatientJwt } from 'src/ts';

export const blindSignupTokenAtom = atom<string | undefined>(undefined);

export const selectedBstPatientAtom = atom<BstPatientJwt | null>(null);

export const birthDateConfirmationAtom = atom<string>('');

export const getBstInfoAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.BST.GET_INFO,
  mutationFn: () => getBstInfoService,
});

export const setTutorToPatientInBstAtom = getAtomWithMutation({
  mutationKey: QUERY_KEYS.BST.UPDATE_PATIENT_TUTOR,
  mutationFn: () => setTutorToPatientInBstService,
});
