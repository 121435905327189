import styles from './signup-form.module.css';
import classNames from 'classnames/bind';
import { useForm } from '@tanstack/react-form';
import { useTranslation } from 'react-i18next';
import { validate } from '../../../../utils';
import { Button } from '../../../../components/Buttons';
import { GlobalService } from '../../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { LoginSignupSwitchLink } from '../../login-signup-switch-link';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import {
  useBlindSignupToken,
  useBstSetAndMobileAlreadyInUsePopup,
  useGetIsMobileRegistered,
} from '../../../../hooks';
import { Input } from '../../../Inputs/input';
import { useNavigate } from 'react-router-dom';
import { AcceptedTermsData } from 'src/ts';

const cx = classNames.bind(styles);

interface SignupForm0Props {
  initialValues: {
    mobile: string;
    password: string;
    passwordConfirmation: string;
    acceptedTermsData: AcceptedTermsData;
    acceptedCommercialTerms: boolean;
    acceptedDataProtectionPolicy: boolean;
  };
  setInitialValues: {
    setMobile: Dispatch<SetStateAction<string>>;
    setPassword: Dispatch<SetStateAction<string>>;
    setPasswordConfirmation: Dispatch<SetStateAction<string>>;
    setAcceptedDataProtectionPolicy: Dispatch<SetStateAction<boolean>>;
    setAcceptedCommercialTerms: Dispatch<SetStateAction<boolean>>;
    setAcceptedTermsData: Dispatch<SetStateAction<AcceptedTermsData>>;
  };
  handleSubmit: () => void;
}

export const SignupForm0: FC<SignupForm0Props> = ({
  initialValues,
  setInitialValues,
  handleSubmit,
}) => {
  const { t } = useTranslation();
  const { isMobileRegistered, getIsMobileRegisteredAsync, cleanIsMobileRegistered } =
    useGetIsMobileRegistered();
  const { showBstSetAndMobileAlreadyInUsePopup } = useBstSetAndMobileAlreadyInUsePopup();
  const { verifyPatientBirthDate } = useBlindSignupToken();
  const textBase = 'pages.signup';
  useEffect(() => {
    if (!isMobileRegistered) return;

    if (verifyPatientBirthDate == null) return;

    showBstSetAndMobileAlreadyInUsePopup(form.state.values.mobile);
  }, [isMobileRegistered]);

  const form = useForm({
    defaultValues: {
      mobile: initialValues.mobile,
      password: initialValues.password,
      passwordConfirmation: initialValues.passwordConfirmation,
      acceptedCommercialTerms: initialValues.acceptedCommercialTerms,
      acceptedDataProtectionPolicy: initialValues.acceptedDataProtectionPolicy,
    },
    onSubmit: async values => {
      cleanIsMobileRegistered();

      const {
        setMobile,
        setPassword,
        setPasswordConfirmation,
        setAcceptedCommercialTerms,
        setAcceptedDataProtectionPolicy,
        setAcceptedTermsData,
      } = setInitialValues;

      setMobile(values.value.mobile);
      setPassword(values.value.password);
      setPasswordConfirmation(values.value.passwordConfirmation);
      setAcceptedCommercialTerms(values.value.acceptedCommercialTerms);
      setAcceptedDataProtectionPolicy(values.value.acceptedDataProtectionPolicy);
      setAcceptedTermsData({
        acceptedCommercialTerms: values.value.acceptedCommercialTerms,
        acceptedDataProtectionPolicy: values.value.acceptedDataProtectionPolicy,
      });

      try {
        const isMobileRegistered = await getIsMobileRegisteredAsync(values.value.mobile);

        if (isMobileRegistered) return;

        handleSubmit();
      } catch (error: any) {
        console.log('error.message', error.message);
      }
    },
  });

  const handleOpenNewTab = () => {
    const url = '/data-protection';
    const fullUrl = `${window.location.origin}${url}`;
    window.open(fullUrl, '_blank');
  };

  const isMobileRegisteredError = isMobileRegistered
    ? GlobalService.uppercaseAfterPointFirstsLetters(
        t('modal.verifyMobile.errors.mobileAlreadyRegistered'),
      )
    : '';

  return (
    <>
      <form
        className={cx('signup-form')}
        onSubmit={e => {
          e.preventDefault();
          e.stopPropagation();
          form.handleSubmit();
        }}
      >
        <form.Field
          name={'mobile'}
          validators={{
            onChange: ({ value }) => {
              cleanIsMobileRegistered();
              const isValid = validate(value, 'phone');
              return isValid ? undefined : 'errors.phoneInvalid';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            const errors = [...field.state.meta.errors];
            if (isMobileRegistered) errors.push(isMobileRegisteredError);

            return (
              <Input
                id={field.name}
                label={'phoneno'}
                placeholder=''
                type={'tel'}
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={errors.join(', ')}
              />
            );
          }}
        />

        <form.Field
          name={'password'}
          validators={{
            onChange: ({ value }) => {
              const isValid = validate(value, 'password');
              return isValid ? undefined : 'errors.passwordInvalid';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            return (
              <Input
                id={field.name}
                label={'password'}
                placeholder=''
                type={'password'}
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.join(', ')}
                enableShowPassword={true}
              />
            );
          }}
        />

        <form.Field
          name={'passwordConfirmation'}
          validators={{
            onChangeListenTo: ['password'],
            onChange: ({ fieldApi, value }) => {
              const password = fieldApi.form.getFieldValue('password');
              return value === password ? undefined : 'errors.passwordsNotMatch';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            return (
              <Input
                id={field.name}
                label={'passwordconfirmation'}
                placeholder=''
                type={'password'}
                value={field.state.value}
                onChange={field.handleChange}
                onBlur={field.handleBlur}
                errorMessage={field.state.meta.errors.join(', ')}
                enableShowPassword={true}
              />
            );
          }}
        />

        <form.Field
          name={'acceptedCommercialTerms'}
          validators={{
            onChange: ({ value }) => {
              if (!value) return 'error';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            const errors = [...field.state.meta.errors];
            return (
              <div className={cx('checkbox-container')}>
                <label htmlFor={field.name} className={cx('checkbox-container-label')}>
                  <div className={cx('checkbox-container-paragraph')}>
                    <p className={cx('paragraph')}>{t(`${textBase}.acceptedCommercialTerms`)}</p>
                    <p className={cx('paragraph-small')}>
                      {t(`${textBase}.bodyAcceptedCommercialTerms`)}
                    </p>
                  </div>
                  <input
                    id={field.name}
                    className={cx('checkbox')}
                    type='checkbox'
                    checked={field.state.value}
                    onChange={e => field.handleChange(e.target.checked)}
                  />
                  <span className={cx('custom-checkmark')}></span>
                </label>
                {errors.some(error => error === 'error') ? (
                  <p className={cx('error-text-checkbox')}>{t(`${textBase}.acceptToContinue`)}</p>
                ) : null}
              </div>
            );
          }}
        />

        <form.Field
          name={'acceptedDataProtectionPolicy'}
          validators={{
            onChange: ({ value }) => {
              if (!value) return 'error';
            },
          }}
          // eslint-disable-next-line react/no-children-prop
          children={field => {
            const errors = [...field.state.meta.errors];
            return (
              <div className={cx('checkbox-container')}>
                <label htmlFor={field.name} className={cx('checkbox-container-label')}>
                  <p className={cx('paragraph')}>
                    {t(`${textBase}.acceptedDataProtectionPolicy`)}{' '}
                    <a onClick={handleOpenNewTab} className={cx('link')}>
                      {t(`${textBase}.dataProtectionPolicy`)}
                    </a>
                  </p>
                  <input
                    className={cx('checkbox')}
                    id={field.name}
                    type='checkbox'
                    checked={field.state.value}
                    onChange={e => field.handleChange(e.target.checked)}
                  />
                  <span className={cx('custom-checkmark')}></span>
                </label>
                {errors.some(error => error === 'error') ? (
                  <p className={cx('error-text-checkbox')}>{t(`${textBase}.acceptToContinue`)}</p>
                ) : null}
              </div>
            );
          }}
        />

        <form.Subscribe
          // @ts-ignore
          selector={state => [state.canSubmit, state.isSubmitting]}
          // @ts-ignore
          // eslint-disable-next-line react/no-children-prop
          children={([canSubmit, isSubmitting]) => (
            <Button disabled={!canSubmit} fullWidth={true} type='submit' isLoading={isSubmitting}>
              {GlobalService.uppercaseFirstLetter(t(`pages.signup.register`))}
            </Button>
          )}
        />
      </form>

      <LoginSignupSwitchLink type='signup' />
    </>
  );
};
