import { useSelectedQuoteValue } from './use-selected-quote-value';
import { useSetSelectedQuote } from './use-set-selected-quote';

export const useSelectedQuote = () => {
  const { selectedQuote } = useSelectedQuoteValue();
  const { setSelectedQuote, removeSelectedQuote } = useSetSelectedQuote();

  return {
    selectedQuote,
    setSelectedQuote,
    removeSelectedQuote,
  };
};
