import { PopupOptionsSetter } from '../../../ts';
import { useSetPopup } from '../use-popup';
import { MobileVerificationForm } from '../../../components';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useTranslation } from 'react-i18next';
import { useMobileVerificationIntervals } from '../../../hooks/mobile';

interface ShowSMSCodePopupParams {
  mobile: string;
  goBack?: () => void;
  handleSubmit: (smsCode: string) => Promise<void>;
  clearSubmitError?: () => void;
  submitError?: string;
}

export const useShowMobileVerificationOpup = () => {
  const { openPopup, closePopup } = useSetPopup();
  const { t } = useTranslation();
  useMobileVerificationIntervals();

  const showMobileVerificationPopup = ({
    mobile,
    goBack,
    handleSubmit,
    clearSubmitError = () => {},
    submitError,
  }: ShowSMSCodePopupParams) => {
    const handleGoBack = () => {
      goBack && goBack();
      closePopup();
    };

    const smsCodePupupOptions: PopupOptionsSetter = {
      title: GlobalService.uppercaseFirstLetter(t('pages.signup.titleVerifyMobile')),
      children: (
        <MobileVerificationForm
          mobile={mobile}
          goBack={handleGoBack}
          handleSubmit={handleSubmit}
          clearSubmitError={clearSubmitError}
          submitError={submitError}
        />
      ),
      disableClickOutside: true,
    };

    openPopup(smsCodePupupOptions);
  };

  return { showMobileVerificationPopup };
};
