import { PopupOptionsSetter } from 'src/ts';
import { useSetPopup } from '../use-popup';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useSettings } from '../../../hooks/use-settings';
import { AVAILABLE_SETTINGS, PHONE_PREFIX } from '../../../consts';
import { useTranslation } from 'react-i18next';

export const useShowEditTreatmentsPopup = () => {
  const { t } = useTranslation();
  const { openPopup, closePopup } = useSetPopup();
  const { getSettingKey } = useSettings();

  const showEditTreatmentPopup = () => {
    const lcPhoneNumber = getSettingKey(AVAILABLE_SETTINGS.CALL);

    const handleClickCall = () => {
      const phoneUrl = `tel:${PHONE_PREFIX}${lcPhoneNumber.replaceAll(' ', '')}`;
      window.open(phoneUrl, '_self');
    };

    const editTreatmentPopupOptions: PopupOptionsSetter = {
      title: GlobalService.uppercaseAfterPointFirstsLetters(
        t('pages.informeDetallado.modal.title'),
      ),
      children: (
        <>
          <p>
            {GlobalService.uppercaseAfterPointFirstsLetters(
              t('pages.informeDetallado.modal.text1'),
            )}
          </p>
          <p>
            {GlobalService.uppercaseFirstLetter(
              t('pages.informeDetallado.modal.text2', {
                phoneNumber: lcPhoneNumber,
              }),
            )}
          </p>
        </>
      ),
      buttons: [
        {
          styleType: 'secondary',
          children: GlobalService.uppercaseFirstLetter(
            t('pages.informeDetallado.modal.confirmBtn'),
          ),
          onClick: handleClickCall,
        },
        {
          styleType: 'primary',
          children: GlobalService.uppercaseFirstLetter(t('goBack')),
          onClick: () => closePopup(),
        },
      ],
    };

    openPopup(editTreatmentPopupOptions);
  };

  return { showEditTreatmentPopup };
};
