import { useAtomValue } from 'jotai';
import { getIsQuoteAudioListenedAtom, setQuoteAudioAsListenedAtom } from '../../store/global/atoms';

export const useQuoteAudioIsListened = () => {
  const setQuoteAudioAsListenedMutation = useAtomValue(setQuoteAudioAsListenedAtom);
  const getIsQuoteAudioListened = useAtomValue(getIsQuoteAudioListenedAtom);

  return {
    setQuoteAudioAsListenedData: setQuoteAudioAsListenedMutation.data,
    setQuoteAudioAsListened: setQuoteAudioAsListenedMutation.mutate,
    isLoadingSetQuoteAudioAsListened: setQuoteAudioAsListenedMutation.isPending,
    setQuoteAudioAsListenedError: setQuoteAudioAsListenedMutation.error,

    isQuoteAudioListened: getIsQuoteAudioListened.data,
    getIsQuoteAudioListened: getIsQuoteAudioListened.mutate,
    isLoadingGetIsQuoteAudioListened: getIsQuoteAudioListened.isPending,
    getIsQuoteAudioListenedError: getIsQuoteAudioListened.error,
  };
};
