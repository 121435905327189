import { atom } from 'jotai';
import { PatientInfo } from '../../../../ts';
import { selectAtom } from 'jotai/vanilla/utils';

// TODO - Make it possible to get the extra info of a single patient
// TODO - When above is done, make it possible to detect the errors when fetching of a single patient, and get all what is OK
export const patientsAtom = atom<PatientInfo[]>([]);

// Used to merge the patients and their extra info. It's moved to patientsAtom once all the info is received and set
export const accumulatedPatientsAtom = atom<PatientInfo[]>([]);

export const isPatientsEmptyAtom = selectAtom(
  patientsAtom,
  state => state.length === 0,
  (a, b) => a !== b,
);
