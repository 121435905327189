import { useAtomValue } from 'jotai';
import { getDocumentInfoAtom } from '../../../store/global/atoms';

export const useGetDocumentInfo = () => {
  const getDocumentInfo = useAtomValue(getDocumentInfoAtom);

  return {
    documentInfo: getDocumentInfo.data,
    getDocumentInfo: getDocumentInfo.mutate,
    getDocumentInfoAsync: getDocumentInfo.mutateAsync,
    error: getDocumentInfo.error,
    isLoading: getDocumentInfo.isPending,
  };
};
