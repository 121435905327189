import { useNavigate } from 'react-router-dom';
import { useResetPatients, useSetSelectedQuote, useSetSession } from '../..';

export const useSignout = () => {
  const { removeSession } = useSetSession();
  const { resetPatients } = useResetPatients();
  const { removeSelectedQuote } = useSetSelectedQuote();
  const navigate = useNavigate();

  const signout = (redirect?: boolean | string) => {
    removeSession();
    resetPatients();
    removeSelectedQuote();

    if (redirect) {
      const route = typeof redirect === 'string' ? redirect : '/login';
      navigate(route);
    }
  };

  return { signout };
};
