import { useSetPopup } from '../use-popup';
import { PopupOptionsSetter } from '../../../ts';
import { EditAddress } from '../../../components';

export const useShowEditAddressPopup = () => {
  const { openPopup } = useSetPopup();

  const editAddressPopupOptions: PopupOptionsSetter = {
    title: '',
    children: <EditAddress />,
  };

  const showEditAddressPopup = () => {
    openPopup(editAddressPopupOptions);
  };

  return { showEditAddressPopup };
};
