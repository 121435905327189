import axios from 'axios';
import { ApiResponse, IsQuoteAudioListened, QuoteAudioIdentifier } from '../../../ts';

export const setQuoteAudioAsListenedService = async ({
  quoteId,
  audioId,
}: QuoteAudioIdentifier) => {
  const response = await axios.post<ApiResponse<IsQuoteAudioListened>>(
    '/patients/set-quote-audio-as-listened',
    { quoteId, audioId },
  );

  const { data } = response;

  if (data.isOk !== true) {
    throw new Error('Error setting audio as listened');
  }

  return data.data;
};
