import { PopupOptionsSetter } from 'src/ts';
import { useSetPopup } from '../use-popup';
import { useTranslation } from 'react-i18next';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';

export const useShowDocumentDownloadOkPopup = () => {
  const { t } = useTranslation();
  const { openPopup, closePopup } = useSetPopup();

  const downloadOkOptions: PopupOptionsSetter = {
    title: GlobalService.uppercaseFirstLetter(t('information')),
    children: (
      <p>
        {GlobalService.uppercaseAfterPointFirstsLetters(t('pages.documentos.documentDownloadOk'))}
      </p>
    ),
    buttons: [
      {
        styleType: 'primary',
        children: GlobalService.uppercaseFirstLetter(t('pages.personas.ok')),
        onClick: () => closePopup(),
      },
    ],
  };

  const showDocumentDownloadOkPopup = () => openPopup(downloadOkOptions);

  return { showDocumentDownloadOkPopup };
};
