export const parseFechaVisita = (fechaVisita: string) => {
  let fechaReport: string[] = [];
  if (fechaVisita) {
    const f = fechaVisita.split('-');
    fechaReport = [f[2], f[1], f[0]];
  } else {
    const ara = new Date();
    const mes = ara.getMonth() + 1;
    const mesData: string = mes.toString().padStart(2, '0');
    fechaReport = [
      ara.getDate().toString().padStart(2, '0'),
      mesData,
      ara.getFullYear().toString(),
    ];
  }
  const fecha = `${fechaReport[0]}/${fechaReport[1].padStart(2, '0')}/${fechaReport[2]}`;
  const fechaRaw = new Date(
    parseInt(fechaReport[2]),
    parseInt(fechaReport[1]) - 1,
    parseInt(fechaReport[0]) + 1,
  ).toISOString();
  return { fecha, fechaRaw };
};
