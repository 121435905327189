import { FC, useState } from 'react';
import { base64ToByteArray } from '../../../utils';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import useAsyncEffect from 'use-async-effect';

interface AudioPlayerProps extends React.HTMLProps<HTMLAudioElement> {
  base64: string;
}

export const AudioPlayer: FC<AudioPlayerProps> = ({ base64, onEnded, ...rest }) => {
  base64 = base64.includes('base64') ? base64.split('base64,')[1] : base64;
  const [audioUrl, setAudioUrl] = useState<string | null>(null);

  const ffmpeg = new FFmpeg();

  useAsyncEffect(async () => {
    const url = await convertAudio();
    setAudioUrl(url);
  }, [base64]);

  const convertAudio = async () => {
    if (!ffmpeg.loaded) await ffmpeg.load();

    const byteArray = base64ToByteArray(base64);

    // Write the file to FFmpeg's virtual file system
    await ffmpeg.writeFile('input.3gp', byteArray);

    // Run the conversion
    await ffmpeg.exec(['-i', 'input.3gp', 'output.mp3']);

    // Read the result
    const data = await ffmpeg.readFile('output.mp3');

    // Create a blob URL
    const audioBlob = new Blob([data], { type: 'audio/mp3' });
    const url = URL.createObjectURL(audioBlob);

    return url;
  };

  return <audio src={audioUrl ?? ''} onEnded={onEnded} controls {...rest}></audio>;
};
