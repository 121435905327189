import { useTranslation } from 'react-i18next';
import { useSetPopup } from '../use-popup';
import { PopupOptionsSetter } from '../../../ts';
import { GlobalService } from '../../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import { useSignout } from '../../../hooks/user-session';

export const useShowPopupSignout = () => {
  const { t } = useTranslation();
  const { openPopup, closePopup } = useSetPopup();
  const { signout } = useSignout();

  const signoutPopupOptions: PopupOptionsSetter = {
    title: GlobalService.uppercaseFirstLetter(t('pages.personas.signout')),
    children: GlobalService.uppercaseAfterPointFirstsLetters(t('pages.personas.signoutText')),
    buttons: [
      {
        styleType: 'secondary',
        children: GlobalService.uppercaseFirstLetter(t('pages.personas.ok')),
        onClick: () => {
          signout(true);
          closePopup();
        },
      },
      {
        styleType: 'primary',
        children: GlobalService.uppercaseFirstLetter(t('cancel')),
        onClick: () => closePopup(),
      },
    ],
  };

  const showPopupSignout = () => {
    openPopup(signoutPopupOptions);
  };

  return { showPopupSignout };
};
