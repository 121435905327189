import styles from './images-gallery.module.css';
import 'photoswipe/style.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import classNames from 'classnames/bind';
import { FC, useLayoutEffect, useRef } from 'react';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

const cx = classNames.bind(styles);

const height = 960;
const width = 720;

interface ImagesGalleryProps {
  images: string[];
  galleryId: string;
}

export const ImagesGallery: FC<ImagesGalleryProps> = ({ images, galleryId }) => {
  const galleryIdText = `gallery-${galleryId}`;
  const lightboxRef = useRef<PhotoSwipeLightbox | null>(null);

  useLayoutEffect(() => {
    lightboxRef.current = new PhotoSwipeLightbox({
      gallery: `#${galleryIdText}`,
      children: 'a',
      pswpModule: () => import('photoswipe'),
    });
    lightboxRef.current.init();

    return () => {
      lightboxRef.current?.destroy();
      lightboxRef.current = null;
    };
  }, []);

  // const imagesObj: ReactImageGalleryItem[] = images.map(image => ({
  //   original: image,
  //   thumbnail: image,
  //   originalHeight: height,
  //   originalWidth: width,
  //   thumbnailHeight: height,
  //   thumbnailWidth: width,
  // }));

  return (
    <>
      {/* <ImageGallery
        additionalClass={cx('image-gallery')}
        items={imagesObj}
        thumbnailPosition='left'
        useTranslate3D={false}
        autoPlay={false}
        showPlayButton={false}
        showBullets={false}
        disableSwipe={false}
        disableThumbnailScroll={false}
        // showFullscreenButton={false}
        // renderFullscreenButton={(onClick, isFullscreen) => (
        //   <button onClick={onClick}>{isFullscreen ? 'minimize' : 'Fullscreen'}</button>
        // )}
      /> */}
      <div className={cx('pswp-gallery', 'image-gallery')} id={galleryIdText}>
        {images.map((image, index) => (
          <a
            key={`${galleryIdText}-${index}`}
            href={image}
            data-pswp-width={width}
            data-pswp-height={height}
            target='_blank'
            rel='noreferrer'
          >
            <img src={image} alt='' />
          </a>
        ))}
      </div>
    </>
  );
};
