import { useTranslation } from 'react-i18next';
import { GlobalService } from '../../hexagonal-architecture-frontend-base/src/domain/services/Global.service';
import styles from './quote-details.module.css';
import classNames from 'classnames/bind';
import { Button, GoBackArrow, ImagesGallery, TopMenu } from '../../components';
import {
  useDownloadQuoteMedia,
  useQuoteAudioIsListened,
  useSelectedPatient,
  useSelectedQuote,
  useViewport,
} from '../../hooks';
import { AUDIO_EXTENSIONS, IMG_EXTENSIONS } from '../../consts';
import { useIsLoadingAllPatients } from '../../hooks';
import Loader from '../../components/Loaders/Loader';
import { useEffect } from 'react';
import { AudioPlayer } from '../../components';
import { base64ToBlob } from '../../utils';
import { useNavigate } from 'react-router-dom';

const cx = classNames.bind(styles);

export const QuoteDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isScreenBig } = useViewport();
  const { selectedPatient } = useSelectedPatient();
  const { selectedQuote } = useSelectedQuote();
  const { isLoadingAndEmpty } = useIsLoadingAllPatients();
  const {
    quoteMedia,
    downloadSelectedQuoteMedia,
    isLoading: isQuoteMediaLoading,
  } = useDownloadQuoteMedia();
  const {
    setQuoteAudioAsListenedData,
    setQuoteAudioAsListened,
    isQuoteAudioListened,
    getIsQuoteAudioListened,
  } = useQuoteAudioIsListened();

  const pageTitle = `${GlobalService.uppercaseFirstLetter(t('pages.informeDetallado.mainTitle'))}:`;
  const responsivePageTitle = GlobalService.uppercaseFirstLetter(
    t('pages.reports.responsiveMainTitle'),
  );

  const handleSetAudioAsListened = (audioId: string) => {
    if (selectedQuote == null) return;
    setQuoteAudioAsListened({ quoteId: selectedQuote?.id, audioId });
  };

  const imageMediaIdType =
    selectedQuote?.media
      .filter(mediaItem => IMG_EXTENSIONS.includes(mediaItem.extension))
      .map(media => ({ id: media.fileId, type: media.extension })) ?? null;

  const audioMediaIdType =
    selectedQuote?.media
      .filter(mediaItem => AUDIO_EXTENSIONS.includes(mediaItem.extension))
      .map(media => ({ id: media.fileId, type: media.extension })) ?? null;

  const imageMedia =
    quoteMedia == null || imageMediaIdType == null
      ? []
      : imageMediaIdType.map(idType => ({
          id: idType.id,
          type: idType.type,
          url: quoteMedia[idType.id],
        }));

  const audioMedia =
    quoteMedia == null || audioMediaIdType == null
      ? []
      : audioMediaIdType.map(idType => ({
          id: idType.id,
          type: idType.type,
          url: quoteMedia[idType.id],
        }));

  useEffect(() => {
    if (selectedQuote == null || audioMedia.length === 0) return;
    getIsQuoteAudioListened({ quoteId: selectedQuote?.id, audioId: audioMedia[0].id });
  }, [selectedQuote, quoteMedia]);

  useEffect(() => {
    downloadSelectedQuoteMedia();
  }, [selectedQuote]);

  const audioMatchesIsQuoteAudioListened =
    audioMedia.length > 0 &&
    isQuoteAudioListened != null &&
    isQuoteAudioListened.identifier.audioId === audioMedia[0].id &&
    isQuoteAudioListened.identifier.quoteId === selectedQuote?.id;

  const audioMatchesSetQuoteAudioAsListenedData =
    audioMedia.length > 0 &&
    setQuoteAudioAsListenedData != null &&
    setQuoteAudioAsListenedData.identifier.audioId === audioMedia[0].id &&
    setQuoteAudioAsListenedData.identifier.quoteId === selectedQuote?.id;

  const isAudioListened =
    audioMedia.length > 0 &&
    ((audioMatchesIsQuoteAudioListened && isQuoteAudioListened.isListened) ||
      (audioMatchesSetQuoteAudioAsListenedData && setQuoteAudioAsListenedData.isListened));

  const backPage = `/informes/${selectedPatient.id}`;
  return (
    <div className={cx('main-container')}>
      <TopMenu
        title={isScreenBig ? pageTitle : responsivePageTitle}
        userName={isScreenBig ? selectedPatient.title : undefined}
        leftIcon={!isScreenBig}
        leftIconLink={backPage}
      />

      <div className={cx('back-arrow-container')}>
        <GoBackArrow to={backPage} />
      </div>

      {isLoadingAndEmpty || isQuoteMediaLoading ? (
        <Loader />
      ) : (
        <div className={cx('body-container')}>
          <div className={cx('media-container')}>
            <div className={cx('audio-and-btn-container', 'container-ui')}>
              <div className={cx('audio-container')}>
                <h2>{t('pages.quoteDetails.audioTitle')}</h2>
                {audioMedia?.map((mediaItem, index) => (
                  <AudioPlayer
                    key={index}
                    base64={mediaItem.url}
                    onEnded={
                      isAudioListened ? undefined : () => handleSetAudioAsListened(mediaItem.id)
                    }
                  />
                ))}
              </div>

              <Button
                className={cx('continue-btn')}
                onClick={() =>
                  navigate(
                    `/informeDetallado/${selectedPatient.id}/${selectedQuote?.id}/confirmation`,
                  )
                }
                disabled={!isAudioListened}
              >
                {t('continue')}
              </Button>
            </div>

            <div className={cx('images-container', 'container-ui')}>
              {imageMedia.length > 0 && selectedQuote != null ? (
                <ImagesGallery
                  images={imageMedia.map(item =>
                    URL.createObjectURL(base64ToBlob(item.url, 'image/png')),
                  )}
                  galleryId={selectedQuote.id}
                />
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
