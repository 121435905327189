import { useAtomValue } from 'jotai';
import { downloadQuoteMediaAtom } from '../../store/global/atoms';
import { useSelectedQuoteValue } from './use-selected-quote';
import { MutationOptions } from '@tanstack/query-core';
import { MediaDownloadData } from 'src/ts';

export const useDownloadQuoteMedia = () => {
  const downloadQuoteMediaMutation = useAtomValue(downloadQuoteMediaAtom);
  const { selectedQuote } = useSelectedQuoteValue();

  const downloadSelectedQuoteMedia = (
    options?: MutationOptions<typeof downloadQuoteMediaMutation.data, Error, MediaDownloadData[]>,
  ) => {
    if (selectedQuote == null) return;

    const files: MediaDownloadData[] = selectedQuote.media.map(
      media => ({ id: media.fileId, fileType: media.extension } as MediaDownloadData),
    );

    if (files.length === 0) return;

    downloadQuoteMediaMutation.mutate(files, options);
  };

  return {
    quoteMedia: downloadQuoteMediaMutation.data,
    downloadSelectedQuoteMedia,
    isLoading: downloadQuoteMediaMutation.isPending,
    error: downloadQuoteMediaMutation.error,
  };
};
