import { Navigate, Outlet } from 'react-router-dom';
import { useBlindSignupToken, useSession } from '../hooks';

const NotLoggedRoutes = () => {
  const { isUserLogged } = useSession();
  useBlindSignupToken();

  return <>{!isUserLogged ? <Outlet /> : <Navigate to={'/personas'} />}</>;
};

export default NotLoggedRoutes;
