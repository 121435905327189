import { useSetAtom } from 'jotai';
import { selectedQuoteIdAtom } from '../../../store/global/atoms';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';

export const useSetSelectedQuote = () => {
  const setSelectedQuoteBase = useSetAtom(selectedQuoteIdAtom);
  const { reportId } = useParams();

  const setSelectedQuote = (reportId: string) => {
    setSelectedQuoteBase(reportId);
  };

  const removeSelectedQuote = () => {
    setSelectedQuoteBase(null);
  };

  useEffect(() => {
    if (reportId == null) return;
    setSelectedQuote(reportId);
  }, [reportId]);

  return {
    setSelectedQuote,
    removeSelectedQuote,
  };
};
